import PropTypes from 'prop-types';
import React from 'react';
import nextId from 'react-id-generator';

const Loading = ({ color }) => {
  const htmlId = nextId();

  return (
    <div id={htmlId} className="loader">
      <div className="loader__wheel" />
      <div className="loader__text">Loading...</div>

      <style jsx>{`
        #${htmlId}.loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 5rem;
          text-align: center;
        }

        #${htmlId} .loader__wheel {
          animation: spin 1s infinite linear, bounce 1s infinite alternate;
          border: 4px solid transparent;
          border-top-color: #234e70; /* Yellow color for the top border */
          border-bottom-color: #234e70; /* Black color for the bottom border */
          border-radius: 50%;
          height: 4rem;
          width: 4rem;
          margin: 0 auto;
        }

        #${htmlId} .loader__text {
          color: ${color};
          font-size: 1.2rem;
          margin-top: 1rem;
          animation: blinkingText 1.2s infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes bounce {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-20px);
          }
        }

        @keyframes blinkingText {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

Loading.propTypes = {
  color: PropTypes.string
};

export default Loading;
