// links
export const API_URL = process.env.REACT_APP_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;

// API functions
export function getDocuments(dataURL, accessToken = false) {
  if (accessToken) {
    return fetch(dataURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      }
    }).then((res) => res.json());
  } else {
    return fetch(dataURL, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
        'User-Agent': 'homesidekick'
      }
    }).then((res) => {
      return res.json();
    });
  }
}
