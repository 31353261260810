import React from 'react';
import { useQuery } from 'react-query';

import { API_URL, getDocuments } from '../../utils/API';

const Home = () => {
  // API handling
  const documentUrl = `${API_URL}/getDocuments`;
  const { error: isDataError, data } = useQuery('documents', () => getDocuments(documentUrl));
  if (isDataError) {
    console.log('An error has occurred: ' + isDataError.message);
  }
  console.log('TEST - ', data);
  return (
    <div>
      <title>At Your Service For Any Home Repair Emergency</title>
      <h2>LANDING PAGE</h2>
      <ul>
        {data?.map((doc) => {
          return (
            <div key={doc.category}>
              <li>{doc}</li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Home;
